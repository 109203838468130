import React, { useEffect } from "react";
import { Link } from 'gatsby'
import { Wrapper } from '../components/site/Wrapper';




const Puzzle = ({ data }) => {
    return <div className="col-12 col-md-6 col-lg-3 my-1">
        <Link to={`./${data.urlEncodedTitle.toLowerCase()}`}>
            {data.title}
        </Link>

    </div>
}


export const CategoryKey = ({ pageContext, limit, ...props }) => {
    // useEffect(() => {
    //     console.log('props', pageContext, props);
    // }, []);
    const { title, puzzles = [] } = pageContext;

    // console.log(puzzles, pageContext);

    return (
        <div>


            <h1>{pageContext.node.name}</h1>
            <div>


                <div>
                    <h2>Puzzle Keys</h2>
                </div>
                <div className="row">
                {
                    [...puzzles.slice(0, limit || puzzles.length)]
                        .sort((puzzle1, puzzle2) => {
                            return puzzle2.title > puzzle1.title ? -1 : 1
                        })
                        .map(puzzle => <Puzzle key={puzzle.title} data={puzzle} />)}

</div>
            </div></div>
    )
}

const ComponentWrapper =  ({ pageContext, ...props }) => {
    return <Wrapper current={pageContext.node.name}>
        <CategoryKey pageContext={pageContext}
            {...props} />
    </Wrapper>
}

export default ComponentWrapper;